import { Link } from "react-router-dom";
import PublicLayout from "layouts/Public";
import LoginForm from "components/forms/Login";

export default function Login(props) {
  return (
    <PublicLayout title="Login | Radhe App (Operator)">
      <div className="text-center pb-4">
        <h1 className="text-4xl font-bold">Login</h1>
        <div className="text-center pt-4">Operator access</div>
      </div>
      <LoginForm />
      <div className="text-center pt-6">
        <Link to="/reset" className="text-secondary">
          Forgot password?
        </Link>
      </div>
    </PublicLayout>
  );
}
