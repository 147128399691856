import { useState, useContext } from "react";
import { AuthContext } from "contexts/Auth";
import { useMutation, useQuery } from "@apollo/client";
import CommentForm from "components/forms/Comment";
import { REMOVE_COMMENT } from "utils/mutations";
import { GET_COMMENTS } from "utils/queries";
import toast from "react-hot-toast";
import LoadingLayout from "layouts/Loading";
import { TrashIcon, PencilIcon } from "@heroicons/react/24/outline";
import { formatISODate } from "utils/helpers";

export default function CommentsList(props) {
  const { entity, reference, alsoRefetch } = props;

  const { user } = useContext(AuthContext);

  const [list, setList] = useState([]);
  const [edit, setEdit] = useState(null);
  const [username] = useState(user?.data?.username);

  const { loading, refetch } = useQuery(GET_COMMENTS, {
    variables: { entity: entity, reference: reference },
    onCompleted: (data) => {
      if (data?.comments?.success) setList(data?.comments?.list);
    },
    onError: (error) => {
      toast.error(error.message);
    },
    fetchPolicy: "network-only",
    pollInterval: 60000,
  });

  const [removeComment] = useMutation(REMOVE_COMMENT, {
    onCompleted: () => {
      toast.success("Comment deleted");
      refetch();
      if (alsoRefetch !== undefined) alsoRefetch();
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const handleDelete = (id) => {
    if (!window.confirm("Are you sure you want to delete this comment?"))
      return;
    removeComment({ variables: { id } });
  };

  if (loading) return <LoadingLayout />;

  return (
    <div>
      <ul className="bg-white px-4 py-2 rounded divide-y">
        {list.length > 0 ? (
          list.map((value, index) => (
            <li
              key={index}
              className="flex flex-row justify-between items-center py-2"
            >
              <div>
                <h3 className="font-semibold">
                  By {value.by === username ? "me" : value.by}:
                </h3>
                <p>{value.message}</p>
                <p className="text-xs mt-1 font-semibold">
                  {formatISODate(value.on, "DD-MM-YYYY hh:mm A")}
                </p>
              </div>
              <div className="flex flex-row gap-4 items-center">
                {value.by === username && (
                  <button
                    className="btn btn-sm btn-circle btn-primary btn-outline"
                    onClick={() =>
                      setEdit({
                        id: value.id,
                        message: value.message,
                      })
                    }
                  >
                    <PencilIcon className="w-4 h-4" />
                  </button>
                )}
                {(user.data.role === "ADMIN" ||
                  user.data.role === "MANAGER" ||
                  value.by === username) && (
                  <button
                    className="btn btn-sm btn-circle btn-secondary btn-outline"
                    onClick={() => handleDelete(value.id)}
                  >
                    <TrashIcon className="w-4 h-4" />
                  </button>
                )}
              </div>
            </li>
          ))
        ) : (
          <h3 className="text-center font-semibold">No comments</h3>
        )}
      </ul>
      <div className="w-full flex flex-row justify-center items-center p-4">
        <CommentForm
          entity={entity}
          reference={reference}
          edit={edit}
          setEdit={setEdit}
          alsoRefetch={alsoRefetch}
        />
      </div>
    </div>
  );
}
