import { useMutation } from "@apollo/client";
import { UPDATE_ORDER_ITEM } from "utils/mutations";
import { LIST_COLLECTABLE_ITEMS } from "utils/queries";
import toast from "react-hot-toast";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

export default function CollectionForm(props) {
  const {
    item,
    operator,
    suppliers,
    selectedSupplier,
    handleSupplier,
    refresh,
    goBack,
  } = props;

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: "onTouched" });

  const [updateCollection, { loading }] = useMutation(UPDATE_ORDER_ITEM, {
    onCompleted: (data) => {
      if (data?.updated?.success) {
        toast.success("Item updated successfully");
        reset();
        refresh();
        goBack();
      }
      if (!data?.updated?.success) toast.error("Item update failed");
    },
    onError: (error) => {
      toast.error(error.message);
      console.error(error);
    },
    refetchQueries: [{ query: LIST_COLLECTABLE_ITEMS }],
  });

  const onSubmit = (data) => {
    const inputs = {
      id: item,
      collect: {
        operator,
        supplier: data.collection_supplier,
        quantity: parseInt(data.collection_quantity * 100),
        cost: parseInt(parseFloat(data.collection_cost).toFixed(2) * 100),
      },
    };

    updateCollection({ variables: { inputs } });
  };

  useEffect(() => {
    if (selectedSupplier) setValue("collection_supplier", selectedSupplier);
  }, [selectedSupplier, setValue]);

  return (
    <div className="w=full">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col sm:flex-row sm:items-end gap-2 sm:gap-4"
      >
        {/* supplier */}
        <div className="flex-1">
          <label htmlFor="collection_supplier" className="label">
            <span className="label-text font-semibold">Supplier</span>
          </label>
          <select
            className="input input-bordered w-full bg-base-200"
            disabled={loading}
            {...register("collection_supplier", {
              required: "Supplier is required",
              onChange: (e) => handleSupplier(e.target.value),
              validate: (value) => {
                if (!value) return "Supplier is required";
                return true;
              },
            })}
          >
            <option value="">Select supplier</option>
            {suppliers.map((value, index) => (
              <option key={index} value={value.id}>
                {value.label}
              </option>
            ))}
          </select>
          {errors?.collection_supplier && (
            <div className="mt-1 text-red-600">
              <small>{errors?.collection_supplier?.message}</small>
            </div>
          )}
        </div>
        <div className="flex-1 flex gap-2">
          {/* quantity */}
          <div className="basis-1/2">
            <label htmlFor="collection_quantity" className="label">
              <span className="label-text font-semibold">Quantity</span>
            </label>
            <input
              type="number"
              className="input input-bordered w-full bg-base-200"
              placeholder="Quantity"
              inputMode="numeric"
              min={0.05}
              step={0.05}
              disabled={loading}
              {...register("collection_quantity", {
                required: "Item collection qty is required",
              })}
            />
            {errors?.collection_quantity && (
              <div className="mt-1 text-red-600">
                <small>{errors?.collection_quantity?.message}</small>
              </div>
            )}
          </div>
          {/* cost */}
          <div className="basis-1/2">
            <label htmlFor="collection_cost" className="label">
              <span className="label-text font-semibold">Cost</span>
            </label>
            <input
              type="number"
              className="input input-bordered w-full bg-base-200"
              placeholder="0.00"
              inputMode="decimal"
              min={0}
              step={0.01}
              disabled={loading}
              {...register("collection_cost", {
                required: "Item collection cost is required",
              })}
            />
            {errors?.collection_cost && (
              <div className="mt-1 text-red-600">
                <small>{errors?.collection_cost?.message}</small>
              </div>
            )}
          </div>
        </div>
        {/* submit */}
        <div className="pt-4">
          <button
            className={clsx(
              "btn btn-primary btn-outline w-full",
              loading && "loading"
            )}
            disabled={!isValid || loading}
          >
            Collect Item
          </button>
        </div>
      </form>
    </div>
  );
}
