import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "@apollo/client";
import { CREATE_TRANSACTION } from "utils/mutations";
import {
  TRANSACTION_CONSTANTS,
  LIST_TRANSACTIONS,
  LIST_BALANCES,
} from "utils/queries";
import toast from "react-hot-toast";
import clsx from "clsx";
import { uploadFile, upperFirst } from "utils/helpers";

export default function PaymentForm(props) {
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [operators, setOperators] = useState([]);
  const [categories, setCategories] = useState([]);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "onTouched" });

  const { loading: initialising } = useQuery(TRANSACTION_CONSTANTS, {
    onCompleted: (data) => {
      if (data?.modules?.length > 0)
        setValue("payment_module", data.modules[0].value);
      setLocations(data?.locations?.locations);
      setOperators(data?.operators?.operators);
      setCategories(data?.categories);
    },
    onError: (error) => {
      toast.error(error.message);
    },
    variables: {
      module: "PRODUCE",
      feature: "TRANSACTION",
      role: "OPERATOR",
    },
  });

  const [createTransaction, { loading: creating }] = useMutation(
    CREATE_TRANSACTION,
    {
      onCompleted: (data) => {
        if (!data?.created?.success)
          toast.error("Transaction creation failed, please check inputs");
        if (data?.created?.success) {
          toast.success("Transaction created successfully");
          reset();
          navigate(-1);
        }
      },
      onError: (error) => {
        toast.error(error.message);
        console.error(error);
      },
      refetchQueries: [
        {
          query: LIST_TRANSACTIONS,
          variables: { module: "PRODUCE", category: "PAYMENT" },
        },
        { query: LIST_BALANCES },
      ],
    }
  );

  const payment_entity_type = watch("payment_entity_type");

  const handleCategoryChange = (e) => {
    const index = e.target.selectedIndex;

    const type = e.target[index].getAttribute("data-type");
    const entity = e.target[index].getAttribute("data-entity");
    const description = e.target[index].getAttribute("data-description");

    setValue("payment_type", type);
    setValue("payment_entity_type", entity);
    setValue("payment_description", description);
  };

  const onSubmit = (data) => {
    const inputs = {
      module: "PRODUCE",
      type: data.payment_type,
      category: data.payment_category,
      description: data.payment_description,
      amount: parseInt(parseFloat(data.payment_amount).toFixed(2) * 100),
      entity: data.payment_entity_type,
    };

    if (data.payment_entity_type === "Location")
      inputs.reference = data.payment_location;

    if (data.payment_entity_type === "Operator")
      inputs.reference = data.payment_operator;

    if (data.payment_receipt.length > 0) {
      setLoading(true);
      uploadFile(data.payment_receipt[0], "receipt").then((res) => {
        if (res.Key) inputs.receipt = res.Key;
        createTransaction({ variables: { inputs } });
      });
    } else {
      inputs.receipt = undefined;
      createTransaction({ variables: { inputs } });
    }
  };

  useEffect(() => {
    if (initialising || creating) setLoading(true);
    else setLoading(false);
  }, [initialising, creating]);

  return (
    <div className="flex flex-col md:flex-row gap-4">
      <div className="basis-1/2 max-w-md">
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* categories */}
          <div>
            <label htmlFor="category" className="label">
              <span className="label-text font-semibold">Category</span>
            </label>
            <select
              name="category"
              className="input input-bordered w-full bg-base-200"
              disabled={loading}
              {...register("payment_category", {
                required: "Category is required",
                onChange: handleCategoryChange,
              })}
            >
              <option value={""}>Select category</option>
              {categories.map((category, index) => (
                <option
                  key={index}
                  value={category.value}
                  data-type={category.type}
                  data-entity={category.entity}
                  data-description={category.description}
                >
                  {upperFirst(category.label)}
                </option>
              ))}
            </select>
            {errors?.payment_category && (
              <div className="mt-1 text-red-600">
                <small>{errors?.payment_category?.message}</small>
              </div>
            )}
          </div>
          {/* entity_type */}
          <div className="hidden">
            <label htmlFor="entity" className="label">
              <span className="label-text font-semibold">Type</span>
            </label>
            <select
              name="entity"
              className="input input-bordered w-full bg-base-200"
              disabled={true}
              {...register("payment_entity_type", {
                required: "Entity is required",
              })}
            >
              <option value={""}>Select entity</option>
              <option value="Location">Location</option>
              <option value="Operator">Operator</option>
            </select>
            {errors?.payment_entity_type && (
              <div className="mt-1 text-red-600">
                <small>{errors?.payment_entity_type?.message}</small>
              </div>
            )}
          </div>
          {/* operators */}
          {payment_entity_type === "Operator" && (
            <div>
              <label htmlFor="operator" className="label">
                <span className="label-text font-semibold">Operator</span>
              </label>
              <select
                name="operator"
                className="input input-bordered w-full bg-base-200"
                disabled={loading}
                {...register("payment_operator", {
                  required: "Operator is required",
                })}
              >
                <option value="">Select operator</option>
                {operators.map((operator, index) => (
                  <option key={index} value={operator.id}>
                    {operator.name}
                  </option>
                ))}
              </select>
              {errors?.payment_operator && (
                <div className="mt-1 text-red-600">
                  <small>{errors?.payment_operator?.message}</small>
                </div>
              )}
            </div>
          )}
          {/* locations */}
          {payment_entity_type === "Location" && (
            <div>
              <label htmlFor="location" className="label">
                <span className="label-text font-semibold">Location</span>
              </label>
              <select
                name="location"
                className="input input-bordered w-full bg-base-200"
                disabled={loading}
                {...register("payment_location", {
                  required: "Location is required",
                })}
              >
                <option value="">Select location</option>
                {locations.map((location, index) => (
                  <option key={index} value={location.id}>
                    {location.name}
                  </option>
                ))}
              </select>
              {errors?.payment_location && (
                <div className="mt-1 text-red-600">
                  <small>{errors?.payment_location?.message}</small>
                </div>
              )}
            </div>
          )}
          {/* type */}
          <div className="hidden">
            <label htmlFor="type" className="label">
              <span className="label-text font-semibold">Credit / Debit</span>
            </label>
            <select
              name="type"
              className="input input-bordered w-full bg-base-200"
              disabled={true}
              {...register("payment_type", {
                required: "Type is required",
              })}
            >
              <option value={""}>Select type</option>
              <option value="CREDIT">Credit</option>
              <option value="DEBIT">Debit</option>
            </select>
            {errors?.payment_type && (
              <div className="mt-1 text-red-600">
                <small>{errors?.payment_type?.message}</small>
              </div>
            )}
          </div>
          {/* description */}
          <div className="hidden">
            <label htmlFor="description" className="label">
              <span className="label-text font-semibold">Description</span>
            </label>
            <input
              type="text"
              name="description"
              rows={5}
              placeholder="Description"
              defaultValue={""}
              className="input input-bordered w-full bg-base-200"
              disabled={loading}
              {...register("payment_description", {
                required: "Description is required",
              })}
            />
            {errors?.payment_description && (
              <div className="text-red-600">
                <small>{errors?.payment_description?.message}</small>
              </div>
            )}
          </div>
          {/* amount */}
          <div>
            <label htmlFor="amount" className="label">
              <span className="label-text font-semibold">Amount</span>
            </label>
            <input
              type="number"
              name="amount"
              step={0.01}
              min={0}
              placeholder="0.00"
              className="input input-bordered w-full bg-base-200"
              disabled={loading}
              {...register("payment_amount", {
                required: "Amount is required",
              })}
            />
            {errors?.payment_amount && (
              <div className="mt-1 text-red-600">
                <small>{errors?.payment_amount?.message}</small>
              </div>
            )}
          </div>
          {/* receipt */}
          <div>
            <label htmlFor="receipt" className="label">
              <span className="label-text font-semibold">Upload receipt</span>
            </label>
            <input
              type="file"
              accept="image/*"
              name="receipt"
              className="input input-bordered bg-base-200 py-1 w-full file:mr-4 file:py-2 file:px-2 file:rounded file:text-sm file:font-semibold file:bg-base-200 hover:file:cursor-pointer file:border-none"
              disabled={loading}
              {...register("payment_receipt")}
            />
            {errors?.payment_receipt && (
              <div className="mt-1 text-red-600">
                <small>{errors?.payment_receipt?.message}</small>
              </div>
            )}
          </div>
          {/* submit */}
          <div className="pt-4">
            <button
              className={clsx(
                "btn btn-primary btn-outline",
                loading && "loading"
              )}
              disabled={loading || !isValid}
            >
              Add Payment
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
