import { useContext } from "react";
import { OrderContext } from "contexts/Order";
import { useNavigate, useParams, Navigate } from "react-router-dom";
import UserLayout from "layouts/User";
import HeadSection from "components/sections/Head";
import PaymentForm from "components/forms/Payment";
import ExpenseForm from "components/forms/Expense";

export default function AddRoute(props) {
  const { canAddItem, canAddExpense } = props;

  const { order } = useContext(OrderContext);

  const { type } = useParams();
  const navigate = useNavigate();

  return (
    <UserLayout title={`Add ${type}`}>
      <HeadSection heading={`Add ${type}`}>
        <button
          className="btn btn-secondary btn-outline"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </HeadSection>
      <section className="pt-8 space-y-8">
        {type &&
          basedOnType(
            type,
            {
              canAddExpense,
              canAddItem,
            },
            { order: order?.id }
          )}
      </section>
    </UserLayout>
  );
}

const basedOnType = (type, permissions, params) => {
  switch (type) {
    // transaction
    case "payment":
      return <PaymentForm />;
    // expense
    case "expense":
      if (!permissions.canAddExpense) return <Navigate to={-1} />;
      return <ExpenseForm {...params} />;
    // otherwise
    default:
      return <Navigate to="/" />;
  }
};
