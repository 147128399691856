import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { unit, nameToSlug } from "./helpers";

// generate produce collect items list PDF
export const collectItemsList = (props) => {
  const { number, date, name = "All Suppliers", items } = props;

  const header = ["#", "Name", "Group", "Requested", "Notified", "Collected"];
  const lineItems = [];

  if (items.length === 0) return;

  items.forEach((item, index) => {
    const itemData = [
      { content: index + 1 },
      { content: `${item.thing.label} (${item.thing.unit})` },
      { content: item.thing.group },
      { content: unit(item.requested ?? 0) },
      { content: unit(item.notified ?? 0) },
      { content: `               ` },
    ];

    lineItems.push(itemData);
  });

  const doc = new jsPDF();

  const alignColumn = (data) => {
    const col = data.column.index;
    if (col === 0 || col === 1 || col === 2) {
      data.cell.styles.cellWidth = "wrap";
      data.cell.styles.minCellWidth = 50;
    }
    if (col === 3 || col === 4) {
      data.cell.styles.halign = "center";
    }
  };

  autoTable(doc, {
    startY: 5,
    margin: { left: 9, right: 9, bottom: 3 }, // This sets the left and right margins
    body: [
      [
        {
          content: "Radhe Wholesale & Retail Pty Ltd",
          styles: {
            halign: "left",
            fontSize: 14,
            textColor: "#ed3137",
            fontStyle: "bold",
          },
        },
        {
          content: "Collection",
          styles: {
            halign: "right",
            fontSize: 14,
            textColor: "#ed3137",
            fontStyle: "bold",
          },
        },
      ],
    ],
    theme: "plain",
  });

  autoTable(doc, {
    startY: 15,
    margin: { left: 9, right: 9 },
    body: [
      [
        {
          content: `Supplier: ${name}`,
          colSpan: 2,
          styles: { halign: "left" },
        },
      ],
      [
        { content: `Order ${number}`, styles: { halign: "left" } },
        { content: `Date: ${date}`, styles: { halign: "right" } },
      ],
      [
        {
          content: `Collection by Operator: ______________________________________________________`,
          colSpan: 2,
        },
      ],
    ],
    styles: { fontSize: 12, fontStyle: "bold" },
    theme: "grid",
  });

  autoTable(doc, {
    margin: { left: 9, right: 9 },
    head: [header],
    body: [...lineItems],
    styles: { fontSize: 11 },
    headStyles: { fillColor: "#343a40" },
    columnStyles: {
      0: { halign: "left" },
      1: { halign: "left", fontStyle: "bold" },
      2: { halign: "left" },
      3: { halign: "center" },
      4: { halign: "center" },
      5: { halign: "left", minCellWidth: 35 },
    },
    didParseCell: (data) => {
      alignColumn(data);
    },
    theme: "grid",
  });

  autoTable(doc, {
    margin: { left: 9, right: 9 },
    body: [
      [
        {
          content: `This is a system generated list (from Radhe Online Ordering System).`,
          styles: {
            halign: "center",
            fontSize: 10,
            fontStyle: "bolditalic",
            textColor: "#4d4d4d",
          },
        },
      ],
    ],
    theme: "plain",
  });

  // add page number of total pages to footer in each page of the document (centered)
  const pageCount = doc.internal.getNumberOfPages();
  doc.setFontSize(10);
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.text(
      `Page ${i} of ${pageCount}`,
      doc.internal.pageSize.getWidth() / 2,
      doc.internal.pageSize.getHeight() - 10,
      { align: "center" }
    );
  }

  doc.save(`CollectList-${number}-${nameToSlug(name)}.pdf`);
};

// generate produce distribute items list PDF
export const distributeItemsList = (props) => {
  const { number, date, name = "All Locations", items } = props;

  const header = ["#", "Name", "Group", "Collected", "Requested", "Delivered"];
  const lineItems = [];

  if (items.length === 0) return;

  items.forEach((item, index) => {
    const itemData = [
      { content: index + 1 },
      { content: `${item.thing.label} (${item.thing.unit})` },
      { content: item.thing.group },
      { content: unit(item.collected ?? 0) },
      { content: unit(item.requested ?? 0) },
      { content: `               ` },
    ];

    lineItems.push(itemData);
  });

  const doc = new jsPDF();

  const alignColumn = (data) => {
    const col = data.column.index;
    if (col === 0 || col === 1 || col === 2) {
      data.cell.styles.cellWidth = "wrap";
      data.cell.styles.minCellWidth = 50;
    }
    if (col === 3 || col === 4) {
      data.cell.styles.halign = "center";
    }
  };

  autoTable(doc, {
    startY: 5,
    margin: { left: 9, right: 9, bottom: 3 }, // This sets the left and right margins
    body: [
      [
        {
          content: "Radhe Wholesale & Retail Pty Ltd",
          styles: {
            halign: "left",
            fontSize: 14,
            textColor: "#ed3137",
            fontStyle: "bold",
          },
        },
        {
          content: "Collection",
          styles: {
            halign: "right",
            fontSize: 14,
            textColor: "#ed3137",
            fontStyle: "bold",
          },
        },
      ],
    ],
    theme: "plain",
  });

  autoTable(doc, {
    startY: 15,
    margin: { left: 9, right: 9 },
    body: [
      [
        {
          content: `Location: ${name}`,
          colSpan: 2,
          styles: { halign: "left" },
        },
      ],
      [
        { content: `Order ${number}`, styles: { halign: "left" } },
        { content: `Date: ${date}`, styles: { halign: "right" } },
      ],
      [
        {
          content: `Distribution by Operator: ______________________________________________________`,
          colSpan: 2,
        },
      ],
    ],
    styles: { fontSize: 12, fontStyle: "bold" },
    theme: "grid",
  });

  autoTable(doc, {
    margin: { left: 9, right: 9 },
    head: [header],
    body: [...lineItems],
    styles: { fontSize: 11 },
    headStyles: { fillColor: "#343a40" },
    columnStyles: {
      0: { halign: "left" },
      1: { halign: "left", fontStyle: "bold" },
      2: { halign: "left" },
      3: { halign: "center" },
      4: { halign: "center" },
      5: { halign: "left", minCellWidth: 35 },
    },
    didParseCell: (data) => {
      alignColumn(data);
    },
    theme: "grid",
  });

  autoTable(doc, {
    margin: { left: 9, right: 9 },
    body: [
      [
        {
          content: `This is a system generated list (from Radhe Online Ordering System).`,
          styles: {
            halign: "center",
            fontSize: 10,
            fontStyle: "bolditalic",
            textColor: "#4d4d4d",
          },
        },
      ],
    ],
    theme: "plain",
  });

  // add page number of total pages to footer in each page of the document (centered)
  const pageCount = doc.internal.getNumberOfPages();
  doc.setFontSize(10);
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.text(
      `Page ${i} of ${pageCount}`,
      doc.internal.pageSize.getWidth() / 2,
      doc.internal.pageSize.getHeight() - 10,
      { align: "center" }
    );
  }

  doc.save(`DistributeList-${number}-${nameToSlug(name)}.pdf`);
};
