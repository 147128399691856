import { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { LIST_TRANSACTIONS, LIST_BALANCES } from "utils/queries";
import { DELETE_TRANSACTION } from "utils/mutations";
import toast from "react-hot-toast";
import { currency } from "utils/helpers";
import { TrashIcon } from "@heroicons/react/24/outline";

export default function OrderExpensesList(props) {
  const { order, info, showAction } = props;

  const { status, counts } = info;

  const [expenses, setExpenses] = useState([]);
  const [summary, setSummary] = useState({ total: 0, individual: 0 });

  const { startPolling, stopPolling, refetch } = useQuery(LIST_TRANSACTIONS, {
    variables: { order, category: "EXPENSE" },
    onCompleted: (data) => {
      if (data?.transactions?.success) {
        const { list } = data.transactions;
        setExpenses(list);
        const total = list.reduce((acc, cur) => acc + cur.amount, 0);
        setSummary({ total, individual: total / counts.locations });
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
    fetchPolicy: "network-only",
    pollInterval: 60000,
  });

  const [deleteTransaction] = useMutation(DELETE_TRANSACTION, {
    onCompleted: (data) => {
      if (data?.deleted?.success) {
        toast.success("Transaction deleted successfully");
        refetch();
      }
    },
    onError: (error) => {
      toast.error(error.message);
      console.error(error);
    },
    refetchQueries: [{ query: LIST_BALANCES }],
  });

  const handleDelete = (id) => {
    if (!window.confirm("Are you sure you want to delete this expense?"))
      return;

    deleteTransaction({ variables: { id } });
  };

  useEffect(() => {
    if (status === "PROCESSING") startPolling(60000);
    else stopPolling();

    return () => stopPolling();
  }, [status, startPolling, stopPolling]);

  return (
    <section>
      <h2 className="text-lg font-semibold mb-2 mx-2">Expenses</h2>
      <ul className="bg-white px-4 py-2 rounded divide-y">
        {expenses.length > 0 ? (
          expenses.map((value) => (
            <li
              key={value.id}
              className="flex flex-row justify-between items-center py-2"
            >
              <div>
                <h3 className="font-semibold">{value.description ?? "-"}</h3>
                <p>Paid by: {value.entity?.label ?? "-"}</p>
              </div>
              <div className="flex flex-row gap-4 items-center">
                <p className="font-semibold text-sm">
                  {currency(value.amount ?? 0)}
                </p>
                {showAction && (
                  <button
                    className="btn btn-sm btn-circle btn-secondary btn-outline"
                    onClick={() => handleDelete(value.id)}
                  >
                    <TrashIcon className="w-4 h-4" />
                  </button>
                )}
              </div>
            </li>
          ))
        ) : (
          <h3 className="text-center font-semibold">No order expenses</h3>
        )}
      </ul>
      <div className="w-full flex justify-center items-center p-2">
        <p className="text-base">
          Total:{" "}
          <span className="font-mono font-bold">{currency(summary.total)}</span>
        </p>
        {status === "COMPLETED" && (
          <>
            <p className="mx-2 text-xs"> |</p>{" "}
            <p className="text-base">
              Per Location:{" "}
              <span className="font-mono font-bold">
                {currency(summary.individual)}
              </span>
            </p>
          </>
        )}
      </div>
    </section>
  );
}
