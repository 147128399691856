import UserLayout from "layouts/User";
import HeadSection from "components/sections/Head";
import ProfileForm from "components/forms/Profile";
import PasswordForm from "components/forms/Password";

export default function Profile(props) {
  return (
    <UserLayout title="Profile">
      <HeadSection heading="Profile"></HeadSection>
      <section className="pt-8 space-y-8 max-w-md divide-y-2">
        <ProfileForm />
        <PasswordForm />
      </section>
    </UserLayout>
  );
}
