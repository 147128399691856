import { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { LIST_ADDABLE_PRODUCTS } from "utils/queries";
import { CREATE_ORDER_ITEM } from "utils/mutations";
import clsx from "clsx";

export default function AddProductsList(props) {
  const { order, module, refresh, close } = props;

  const [adding, setAdding] = useState(null);
  const [products, setProducts] = useState([]);

  const { refetch } = useQuery(LIST_ADDABLE_PRODUCTS, {
    variables: {
      order,
      module,
    },
    onCompleted: (data) => {
      if (data.products?.success) {
        setProducts(data.products?.list);
      }
    },
    onError: (error) => {
      console.error(error.message);
      toast.error("Failed to fetch products");
    },
    fetchPolicy: "network-only",
  });

  const [createItem] = useMutation(CREATE_ORDER_ITEM, {
    onCompleted: (data) => {
      setAdding(null);
      if (data.created?.success) {
        toast.success("Item added successfully");
        close();
        refresh();
        refetch();
      } else {
        toast.error("Failed to add item");
      }
    },
    onError: (error) => {
      setAdding(null);
      console.error(error.message);
      toast.error("Failed to add item");
    },
  });

  const handleAdd = (id) => {
    setAdding(id);

    const inputs = {
      order,
      product: id,
    };

    createItem({ variables: { inputs } });
  };

  return (
    <div>
      <ul className="divide-y">
        {products.map((product) => (
          <li
            key={product.id}
            className="flex justify-between items-center p-2"
          >
            <div className="flex items-center gap-2">
              <div className="relative z-10 flex-shrink-0 w-20">
                <img
                  src={product.thumbnail}
                  alt={product.label}
                  className="object-cover rounded aspect-square"
                />
              </div>
              <div className="flex flex-col">
                <h2 className="font-bold">
                  <span>{product.label}</span>
                </h2>
                <p className="space-x-2 text-xs">
                  <span>{product.group}</span>
                  <span>({product.unit})</span>
                </p>
              </div>
            </div>
            <div>
              <button
                className={clsx(
                  "btn btn-outline btn-sm btn-primary",
                  adding === product.id && "loading"
                )}
                onClick={() => handleAdd(product.id)}
              >
                Add
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
