import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { LIST_ORDERS } from "utils/queries";
import toast from "react-hot-toast";

// context
export const OrderContext = createContext();

// provider component
const OrderProvider = ({ children }) => {
  const [order, setOrder] = useState(null);

  const navigate = useNavigate();

  // set the order value
  const setOrderValue = (newOrder) => setOrder(newOrder);

  // reset the order value
  const resetOrderValue = () => {
    setOrder(null);
    navigate("/");
  };

  const {
    refetch: refetchOrder,
    startPolling: startPollingOrder,
    stopPolling: stopPollingOrder,
  } = useQuery(LIST_ORDERS, {
    variables: { module: "PRODUCE", status: "PROCESSING", limit: 1 },
    // pollInterval: 15000,
    onCompleted: (data) => {
      if (data.orders?.success) {
        if (data.orders.total > 0) {
          const firstOrder = data.orders.list[0];
          if (firstOrder.id !== order?.id) {
            setOrderValue(firstOrder);
          }
        } else resetOrderValue();
      } else resetOrderValue();
    },
    onError: (error) => {
      toast.error(error.message);
      console.error(error.message);
      resetOrderValue();
    },
    fetchPolicy: "network-only",
  });

  // provide the order value and functions to the child components
  return (
    <OrderContext.Provider
      value={{ order, refetchOrder, startPollingOrder, stopPollingOrder }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export default OrderProvider;
