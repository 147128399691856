import { useState } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_ORDER_ITEM } from "utils/mutations";
import toast from "react-hot-toast";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { currency, unit } from "utils/helpers";
import clsx from "clsx";

export default function CollectionList(props) {
  const { item, list, refresh } = props;

  const [updating, setUpdating] = useState(null);

  const [updateCollection] = useMutation(UPDATE_ORDER_ITEM, {
    onCompleted: (data) => {
      setUpdating(null);
      if (data?.updated?.success) {
        toast.success("Item updated successfully");
        refresh();
      }
      if (!data?.updated?.success) toast.error("Item update failed");
    },
    onError: (error) => {
      setUpdating(null);
      toast.error(error.message);
      console.error(error);
    },
  });

  const handleRemove = (id) => {
    if (!window.confirm("Are you sure you want to remove this collection?"))
      return;

    setUpdating(id);

    const inputs = {
      id: item,
      collect: {
        supplier: id,
        quantity: 0,
        cost: 0,
      },
    };

    updateCollection({ variables: { inputs } });
  };

  return (
    <div className="bg-white rounded p-2">
      <ul className="flex flex-col divide-y">
        {list?.length > 0 ? (
          list.map((value, index) => (
            <li className="flex flex-row items-center gap-2 p-4" key={index}>
              <div className="flex flex-col md:flex-row justify-between md:items-center w-full">
                <div>
                  <h3 className="text-md font-semibold tracking-wider">
                    {value.name}
                  </h3>
                </div>
                <div className="flex flex-row items-center divide-x">
                  <p className="pr-2">
                    Notified:{" "}
                    <span className="font-semibold">
                      {unit(value.notified)}
                    </span>
                  </p>
                  <p className="px-2">
                    Collected:{" "}
                    <span className="font-semibold">
                      {unit(value.collected)}
                    </span>
                  </p>
                  <p className="pl-2">
                    Cost:{" "}
                    <span className="font-semibold">
                      {currency(value.cost)}
                    </span>
                  </p>
                </div>
              </div>
              <button
                className={clsx(
                  "btn btn-circle btn-sm btn-ghost text-error",
                  updating === value.id && "loading"
                )}
                disabled={updating === value.id || value.collected === 0}
                onClick={() => handleRemove(value.id)}
              >
                {updating !== value.id && <XCircleIcon className="h-7 w-7" />}
              </button>
            </li>
          ))
        ) : (
          <h3 className="text-center font-semibold">
            No supplier notifications or collections for this item
          </h3>
        )}
      </ul>
    </div>
  );
}
