import { currency } from "utils/helpers";

export default function ItemStats(props) {
  const { requested, notified, collected, distributed, average, total } = props;

  return (
    <div className="flex flex-col gap-4 bg-white rounded">
      <div className="flex flex-col md:flex-row md:justify-between divide-y md:divide-y-0">
        {/* Requested */}
        <div className="min-w-fit md:text-center md:border-r px-4 py-2 md:py-4 basis-1/5">
          <p className="space-x-2 md:space-x-1">
            <span className="label md:pt-1 text-gray-500 inline-block md:block">
              Requested:
            </span>
            <span className="font-bold">{requested / 100}</span>
          </p>
        </div>
        {/* Notified */}
        <div className="min-w-fit md:text-center md:border-r px-4 py-2 md:py-4 basis-1/5">
          <p className="space-x-2 md:space-x-1">
            <span className="label md:pt-1 text-gray-500 inline-block md:block">
              Notified:
            </span>
            <span className="font-bold">{notified / 100}</span>
          </p>
        </div>
        {/* Collected */}
        <div className="min-w-fit md:text-center md:border-r px-4 py-2 md:py-4 basis-1/5">
          <p className="space-x-2 md:space-x-1">
            <span className="label md:pt-1 text-gray-500 inline-block md:block">
              Collected:
            </span>
            <span className="font-bold">{collected / 100}</span>
          </p>
        </div>
        {/* Distributed */}
        <div className="min-w-fit md:text-center md:border-r px-4 py-2 md:py-4 basis-1/5">
          <p className="space-x-2 md:space-x-1">
            <span className="label md:pt-1 text-gray-500 inline-block md:block">
              Distributed:
            </span>
            <span className="font-bold">{distributed / 100}</span>
          </p>
        </div>
        {/* Average Cost */}
        <div className="min-w-fit md:text-center md:border-r px-4 py-2 md:py-4 basis-1/5">
          <p className="space-x-2 md:space-x-1">
            <span className="label md:pt-1 text-gray-500 inline-block md:block">
              Avg. Cost:
            </span>
            <span className="font-bold">{currency(average)}</span>
          </p>
        </div>
        {/* Total Cost */}
        <div className="min-w-fit md:text-center px-4 py-2 md:py-4 basis-1/5">
          <p className="space-x-2 md:space-x-1">
            <span className="label md:pt-1 text-gray-500 inline-block md:block">
              Total:
            </span>
            <span className="font-bold">{currency(total)}</span>
          </p>
        </div>
      </div>
    </div>
  );
}
