import { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  EXPENSE_CONSTANTS,
  LIST_TRANSACTIONS,
  LIST_BALANCES,
} from "utils/queries";
import { CREATE_TRANSACTION } from "utils/mutations";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import clsx from "clsx";
import { uploadFile } from "utils/helpers";

export default function ExpenseForm(props) {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const order = searchParams.get("order");
  if (!order) navigate(-1);

  const [loading, setLoading] = useState(false);
  const [descriptions, setDescriptions] = useState([]);
  const [locations, setLocations] = useState([]);
  const [operators, setOperators] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "onTouched" });

  useQuery(EXPENSE_CONSTANTS, {
    onCompleted: (data) => {
      setDescriptions(data.categories);
      setLocations(data.locations.locations);
      setOperators(data.operators.operators);
    },
    onError: (error) => {
      toast(error.message);
      console.error(error);
    },
  });

  const [createTransaction, { loading: creating }] = useMutation(
    CREATE_TRANSACTION,
    {
      onCompleted: (data) => {
        if (!data?.created?.success)
          toast.error("Transaction creation failed, please check inputs");
        if (data?.created?.success) {
          toast.success("Transaction created successfully");
          reset();
          navigate(-1);
          setLoading(false);
        }
      },
      onError: (error) => {
        toast.error(error.message);
        console.error(error);
        setLoading(false);
      },
      refetchQueries: [
        { query: LIST_TRANSACTIONS, variables: { order, category: "EXPENSE" } },
        { query: LIST_BALANCES },
      ],
    }
  );

  const entityType = watch("expense_entity_type");

  const onSubmit = (data) => {
    const inputs = {
      module: "PRODUCE",
      type: "CREDIT",
      category: "EXPENSE",
      description: data.expense_description,
      amount: parseInt(parseFloat(data.expense_amount).toFixed(2) * 100),
      order,
    };

    if (entityType === "Location") {
      inputs.entity = "Location";
      inputs.reference = data.expense_location;
    }

    if (entityType === "Operator") {
      inputs.entity = "Operator";
      inputs.reference = data.expense_operator;
    }

    if (data.expense_receipt.length > 0) {
      setLoading(true);
      uploadFile(data.expense_receipt[0], "receipt").then((res) => {
        if (res.Key) inputs.receipt = res.Key;
        createTransaction({ variables: { inputs } });
      });
    } else {
      inputs.receipt = undefined;
      createTransaction({ variables: { inputs } });
    }
  };

  return (
    <div className="max-w-md">
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* type */}
        <div>
          <label htmlFor="entity" className="label">
            <span className="label-text font-semibold">Type</span>
          </label>
          <select
            name="entity"
            className="input input-bordered w-full bg-base-200"
            disabled={creating}
            {...register("expense_entity_type", {
              required: "Entity is required",
            })}
          >
            <option value={""}>Select entity</option>
            <option value="Location">Location</option>
            <option value="Operator">Operator</option>
          </select>
          {errors?.expense_entity_type && (
            <div className="mt-1 text-red-600">
              <small>{errors?.expense_entity_type?.message}</small>
            </div>
          )}
        </div>
        {/* operator */}
        {entityType === "Operator" && (
          <div>
            <label htmlFor="expense_operator" className="label">
              <span className="label-text font-semibold">Operator</span>
            </label>
            <select
              className="input input-bordered w-full bg-base-200"
              disabled={creating}
              {...register("expense_operator", {
                required: "Operator is required",
              })}
            >
              <option value="">Select operator</option>
              {operators.map((value, index) => (
                <option key={index} value={value.id}>
                  {value.name}
                </option>
              ))}
            </select>
            {errors?.expense_operator && (
              <div className="mt-1 text-red-600">
                <small>{errors?.expense_operator?.message}</small>
              </div>
            )}
          </div>
        )}
        {/* location */}
        {entityType === "Location" && (
          <div>
            <label htmlFor="expense_location" className="label">
              <span className="label-text font-semibold">Location</span>
            </label>
            <select
              className="input input-bordered w-full bg-base-200"
              disabled={creating}
              {...register("expense_location", {
                required: "Category is required",
              })}
            >
              <option value="">Select category</option>
              {locations.map((value, index) => (
                <option key={index} value={value.id}>
                  {value.name}
                </option>
              ))}
            </select>
            {errors?.expense_location && (
              <div className="mt-1 text-red-600">
                <small>{errors?.expense_location?.message}</small>
              </div>
            )}
          </div>
        )}
        {/* description */}
        <div>
          <label htmlFor="expense_description" className="label">
            <span className="label-text font-semibold">Description</span>
          </label>

          <select
            className="input input-bordered w-full bg-base-200"
            disabled={creating}
            {...register("expense_description", {
              required: "Description is required",
            })}
          >
            <option value="">Select description</option>
            {descriptions.map((value, index) => (
              <option key={index} value={value.id}>
                {value.label}
              </option>
            ))}
          </select>
          {errors?.expense_description && (
            <div className="mt-1 text-red-600">
              <small>{errors?.expense_description?.message}</small>
            </div>
          )}
        </div>
        {/* amount */}
        <div>
          <label htmlFor="expense_amount" className="label">
            <span className="label-text font-semibold">Amount</span>
          </label>
          <input
            type="number"
            className="input input-bordered w-full bg-base-200"
            disabled={creating}
            min={0}
            placeholder="0.00"
            {...register("expense_amount", {
              required: "Amount is required",
            })}
          />
          {errors?.expense_amount && (
            <div className="mt-1 text-red-600">
              <small>{errors?.expense_amount?.message}</small>
            </div>
          )}
          {/* receipt */}
          <div>
            <label htmlFor="receipt" className="label">
              <span className="label-text font-semibold">Upload receipt</span>
            </label>
            <input
              type="file"
              accept="image/*"
              name="receipt"
              className="input input-bordered bg-base-200 py-1 w-full file:mr-4 file:py-2 file:px-2 file:rounded file:text-sm file:font-semibold file:bg-base-200 hover:file:cursor-pointer file:border-none"
              disabled={creating}
              {...register("expense_receipt")}
            />
            {errors?.expense_receipt && (
              <div className="mt-1 text-red-600">
                <small>{errors?.expense_receipt?.message}</small>
              </div>
            )}
          </div>
          {/* submit */}
          <div className="pt-4">
            <button
              className={clsx(
                "btn btn-primary btn-outline",
                creating && "loading"
              )}
              disabled={loading || creating || !isValid}
            >
              Add Expense
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
