import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Link, NavLink } from "react-router-dom";
import { AuthContext } from "contexts/Auth";
import { OrderContext } from "contexts/Order";
import logo from "assets/logo.svg";
import {
  Bars3CenterLeftIcon,
  ArchiveBoxIcon,
  EllipsisHorizontalIcon,
  TruckIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";

const year = new Date().getFullYear();

export default function UserLayout(props) {
  const { user, logout } = useContext(AuthContext);

  const { order } = useContext(OrderContext);

  const permissions = user?.data?.permissions || [];

  const menu = () => {
    const arr = user?.data?.permissions;

    const canAccessCollect = arr?.includes("COLLECT") ?? false;
    const canAccessDistribute = arr?.includes("DISTRIBUTE") ?? false;
    const canAccessPayments = arr?.includes("PAYMENTS") ?? false;

    const items = [];

    if (order && canAccessCollect) {
      items.push({
        label: "Collect",
        path: `/order/${order.id}/collect`,
        icon: <ArchiveBoxIcon className="h-6 w-6" />,
      });
    }

    if (order && canAccessDistribute) {
      items.push({
        label: "Distribute",
        path: `/order/${order.id}/distribute`,
        icon: <TruckIcon className="h-6 w-6" />,
      });
    }

    if (canAccessPayments) {
      items.push({
        label: "Payments",
        path: "/payments",
        icon: <CurrencyDollarIcon className="h-6 w-6" />,
      });
    }

    return items;
  };

  return (
    <>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      <header className="navbar bg-white fixed top-0 z-40 shadow">
        <div className="navbar-start gap-4">
          <div className="dropdown">
            <label
              tabIndex={0}
              className="btn btn-ghost text-secondary lg:hidden"
            >
              <Bars3CenterLeftIcon className="w-6 h-6 stroke-current" />
            </label>
            <ul
              tabIndex={0}
              className="menu menu-normal dropdown-content mt-6 p-2 shadow bg-white text-secondary rounded-box w-52"
            >
              <li className="gap-2">
                {menu().map((item, index) => {
                  if (!item.label) return <></>;
                  return (
                    <NavLink key={index} to={item.path}>
                      {item.icon}
                      {item.label}
                    </NavLink>
                  );
                })}
              </li>
            </ul>
          </div>
          <Link to="/">
            <img src={logo} alt="App logo" className="w-36" />
          </Link>
        </div>
        <div className="navbar-center hidden lg:flex">
          {permissions && permissions.length > 0 && (
            <ul className="menu menu-horizontal text-secondary px-1">
              <li className="gap-2">
                {menu().map((item, index) => {
                  if (!item.label) return <></>;
                  return (
                    <NavLink key={index} to={item.path}>
                      {item.icon}
                      {item.label}
                    </NavLink>
                  );
                })}
              </li>
            </ul>
          )}
        </div>
        <div className="navbar-end">
          <div className="flex gap-2">
            <div className="dropdown dropdown-end">
              <label tabIndex="0" className="btn btn-secondary btn-outline">
                <EllipsisHorizontalIcon className="inline-block w-5 h-5 stroke-current" />
              </label>
              <ul
                tabIndex="0"
                className="dropdown-content menu p-2 shadow bg-white rounded-box w-52 mt-6"
              >
                <li>
                  <Link
                    className="uppercase text-secondary font-semibold"
                    to="/profile"
                  >
                    My Profile
                  </Link>
                </li>
                <li>
                  <div
                    className="uppercase text-secondary font-semibold"
                    onClick={() => logout()}
                  >
                    Logout
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <main className="w-full max-w-7xl mx-auto py-8 px-4 mt-20">
        {props.children}
      </main>
      <footer className="w-full max-w-7xl mx-auto pt-8 px-4">
        <div className="footer footer-center p-4 text-base-content border-t-2">
          <div>
            <p>
              Copyright © {year} - All right reserved by Radhe Group - Powered
              by{" "}
              <a href="https://logicsync.au" target="_blank" rel="noreferrer">
                LogicSync
              </a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}
