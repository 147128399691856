import { useState } from "react";
import { useQuery } from "@apollo/client";
import { LIST_BALANCES } from "utils/queries";
import toast from "react-hot-toast";
import clsx from "clsx";
import { currency } from "utils/helpers";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

export default function BalancesList(props) {
  const [list, setList] = useState([]);

  const { refetch } = useQuery(LIST_BALANCES, {
    onCompleted: (data) => {
      const locationBalances = data.locations.locations;
      const operatorBalances = data.operators.operators;

      const balances = [...locationBalances, ...operatorBalances];

      // keep only the balances that are greater than 0 or less than 0
      const filteredBalances = balances.filter(
        (balance) => balance.balance.produce !== 0
      );

      setList(filteredBalances);
    },
    onError: (error) => {
      toast.error("Failed to load balances");
      console.error(error);
    },
    fetchPolicy: "network-only",
  });

  return (
    <section>
      <div className="flex justify-between">
        <h2 className="text-lg font-semibold mb-2 mx-2">Balances</h2>
        <button className="btn btn-sm btn-ghost" onClick={() => refetch()}>
          <ArrowPathIcon className="w-4 h-4" />
        </button>
      </div>
      <div className="bg-white rounded p-2">
        <ul className="flex flex-col divide-y">
          {list?.length > 0 ? (
            list.map((value) => (
              <li
                className="flex flex-row justify-between items-center gap-2 px-4 py-3"
                key={value.id}
              >
                <div>
                  <h3 className="text-md font-semibold tracking-wider">
                    {value.name}
                  </h3>
                </div>
                <div className="flex flex-row items-center divide-x">
                  <p
                    className={clsx(
                      "px-2 font-semibold",
                      value.balance.produce < 0
                        ? "text-red-600"
                        : "text-green-700"
                    )}
                  >
                    {currency(value.balance.produce)}
                  </p>
                </div>
              </li>
            ))
          ) : (
            <h3 className="text-center font-semibold">No balances found</h3>
          )}
        </ul>
      </div>
    </section>
  );
}
