import { Link } from "react-router-dom";
import UserLayout from "layouts/User";
import HeadSection from "components/sections/Head";
import BalancesList from "components/lists/Balances";
import TransactionsList from "components/lists/Transactions";

export default function Payments(props) {
  return (
    <UserLayout title="Payments">
      <HeadSection heading="Payments">
        <Link className="btn btn-secondary btn-outline" to="/add/payment">
          Add Payment
        </Link>
      </HeadSection>
      <div className="flex flex-col gap-6 pt-6">
        <>
          <BalancesList />
        </>
        <>
          <TransactionsList category="PAYMENT" limit={15} />
        </>
      </div>
    </UserLayout>
  );
}
