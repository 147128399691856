import { useContext, useEffect } from "react";
import { OrderContext } from "contexts/Order";
import { useNavigate } from "react-router-dom";
import UserLayout from "layouts/User";
import { formatISODate } from "utils/helpers";

export default function Home(props) {
  const { canCollect, canDistribute } = props;

  const { order, startPollingOrder, stopPollingOrder } =
    useContext(OrderContext);

  const navigate = useNavigate();

  const onProcess = (id) => {
    if (canCollect) {
      navigate(`/order/${id}/collect`);
      return;
    }
    if (canDistribute) {
      navigate(`/order/${id}/distribute`);
      return;
    }
  };

  useEffect(() => {
    startPollingOrder(1000);
    return () => stopPollingOrder();
  }, [startPollingOrder, stopPollingOrder]);

  return (
    <UserLayout title={`Home`}>
      <div className="w-full py-10 flex justify-center items-center">
        <div className="bg-white rounded p-6 flex flex-col gap-4 min-w-[300px]">
          {!order && (
            <div className="flex justify-center items-center">
              <svg
                className="animate-spin -ml-1 mr-3 h-8 w-8 text-base"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <p>Fetching order to process...</p>
            </div>
          )}
          {order && (
            <div className="flex flex-col gap-2">
              <small className="text-center font-semibold">
                Order to ready to process
              </small>
              <h1 className="text-center text-2xl font-bold">{order.number}</h1>
              <p className="text-center text-sm text-gray-500">
                {formatISODate(order.date, "DD-MM-YYYY")} / {order.counts.items}{" "}
                items
              </p>
              {!canCollect && !canDistribute ? (
                <p className="text-center p-4 max-w-md italic">
                  User does not have enough access to process order
                </p>
              ) : (
                <button
                  className="btn btn-primary w-full"
                  onClick={() => onProcess(order.id)}
                >
                  Start Processing
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </UserLayout>
  );
}
