import { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { LIST_TRANSACTIONS, LIST_BALANCES } from "utils/queries";
import { DELETE_TRANSACTION } from "utils/mutations";
import { currency, formatISODate } from "utils/helpers";
import toast from "react-hot-toast";
import { TrashIcon, ArrowPathIcon } from "@heroicons/react/24/outline";

export default function TransactionsList(props) {
  const { category, limit = 10 } = props;

  const [payments, setPayments] = useState([]);

  const { refetch } = useQuery(LIST_TRANSACTIONS, {
    variables: { category, limit },
    onCompleted: (data) => {
      if (data?.transactions?.success) {
        setPayments(data.transactions.list);
      }
    },
    onError: (error) => {
      toast.error("Failed to load transactions");
      console.error(error);
    },
    fetchPolicy: "network-only",
  });

  const [deleteTransaction] = useMutation(DELETE_TRANSACTION, {
    onCompleted: (data) => {
      if (data?.deleted?.success) {
        toast.success("Transaction deleted successfully");
        refetch();
      }
    },
    onError: (error) => {
      toast.error(error.message);
      console.error(error);
    },
    refetchQueries: [{ query: LIST_BALANCES }],
  });

  const handleDelete = (id) => {
    if (!window.confirm("Are you sure you want to delete this transaction?"))
      return;

    deleteTransaction({ variables: { id } });
  };

  return (
    <section>
      <div className="flex justify-between">
        <h2 className="text-lg font-semibold mb-2 mx-2">Recent Payments</h2>
        <button className="btn btn-sm btn-ghost" onClick={() => refetch()}>
          <ArrowPathIcon className="w-4 h-4" />
        </button>
      </div>
      <div className="bg-white rounded p-2">
        <ul className="flex flex-col divide-y">
          {payments?.length > 0 ? (
            payments.map((value, index) => (
              <li
                className="flex flex-row items-center gap-2 p-4"
                key={value.id}
              >
                <div className="flex flex-col md:flex-row justify-between md:items-center w-full gap-2">
                  <div>
                    <h3 className="text-md font-semibold tracking-wider">
                      #{index + 1} {value.entity.label}
                    </h3>
                    <p className="font-medium text-sm pr-2 flex flex-col sm:flex-row divide-x">
                      <span className="font-normal sm:pr-2">
                        {formatISODate(value.date, "DD-MM-YYYY hh:mm A")}
                      </span>
                      <span className="sm:pl-2">{value.description}</span>
                    </p>
                  </div>
                  <div className="flex flex-row items-center divide-x">
                    {value.type === "DEBIT" && (
                      <p>
                        DB.{" "}
                        <span className="font-semibold">
                          -{currency(value.amount)}
                        </span>
                      </p>
                    )}
                    {value.type === "CREDIT" && (
                      <p>
                        CR.{" "}
                        <span className="font-semibold">
                          +{currency(value.amount)}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
                <button
                  className="btn btn-sm btn-circle btn-secondary btn-outline"
                  onClick={() => handleDelete(value.id)}
                >
                  <TrashIcon className="w-4 h-4" />
                </button>
              </li>
            ))
          ) : (
            <h3 className="text-center font-semibold">No payments found</h3>
          )}
        </ul>
      </div>
    </section>
  );
}
