import { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from "contexts/Auth";
import OrderProvider from "contexts/Order";
import Login from "pages/Login";
import Reset from "pages/Reset";
import Profile from "pages/Profile";
import Home from "pages/Home";
import Order from "pages/Order";
import Item from "pages/Item";
import Payments from "pages/Payments";
import AddRoute from "pages/Add";
import Error from "pages/Error";

function App() {
  const { user } = useContext(AuthContext);

  const permissions = user?.data?.permissions || [];

  const canAccessCollect = permissions?.includes("COLLECT") ?? false;
  const canAccessDistribute = permissions?.includes("DISTRIBUTE") ?? false;
  const canAccessPayments = permissions?.includes("PAYMENTS") ?? false;
  const canAddExpense = permissions?.includes("EXPENSE") ?? false;
  const canAddProduct = permissions?.includes("PRODUCT") ?? false;
  const canCompleteOrder = permissions?.includes("COMPLETE") ?? false;

  return (
    <Routes>
      {/* public routes */}
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/reset"
        element={
          <PublicRoute>
            <Reset />
          </PublicRoute>
        }
      />
      {/* protected routes */}
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Home
              canCollect={canAccessCollect}
              canDistribute={canAccessDistribute}
              canPayments={canAccessPayments}
            />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      {canAccessCollect && (
        <Route
          path="/order/:id/collect"
          element={
            <ProtectedRoute>
              <Order
                mode="COLLECT"
                canAddExpense={canAddExpense}
                canAddProduct={canAddProduct}
                canCompleteOrder={canCompleteOrder}
              />
            </ProtectedRoute>
          }
        />
      )}
      {canAccessDistribute && (
        <Route
          path="/order/:id/distribute"
          element={
            <ProtectedRoute>
              <Order
                mode="DISTRIBUTE"
                canAddExpense={canAddExpense}
                canAddProduct={canAddProduct}
                canCompleteOrder={canCompleteOrder}
              />
            </ProtectedRoute>
          }
        />
      )}
      <Route
        path="/item/:id"
        element={
          <ProtectedRoute>
            <Item
              canCollect={canAccessCollect}
              canDistribute={canAccessDistribute}
            />
          </ProtectedRoute>
        }
      />
      {canAccessPayments && (
        <Route
          path="/payments"
          element={
            <ProtectedRoute>
              <Payments />
            </ProtectedRoute>
          }
        />
      )}
      <Route
        path="/add/:type"
        element={
          <ProtectedRoute>
            <AddRoute
              canAddExpense={canAddExpense}
              canAddProduct={canAddProduct}
            />
          </ProtectedRoute>
        }
      />
      {/* open routes */}
      <Route path="*" element={<Error />} />
    </Routes>
  );
}

export default App;

function PublicRoute(props) {
  const { user } = useContext(AuthContext);
  // user not logged in
  return <>{!user ? props.children : <Navigate to="/" />}</>;
}

function ProtectedRoute(props) {
  const { user } = useContext(AuthContext);
  // admin or manager user
  return (
    <OrderProvider>
      {user ? props.children : <Navigate to="/login" />}
    </OrderProvider>
  );
}
