import React from "react";
import ReactDOM from "react-dom/client";
import "styles/index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import AuthService from "utils/auth";
import { AuthProvider } from "contexts/Auth";
import { HelmetProvider } from "react-helmet-async";
import { Toaster } from "react-hot-toast";

// create http link
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_SERVER,
});
// create an error link
const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      const messages = ["UNAUTHENTICATED", "FORBIDDEN", "UNAUTHORIZED"];
      if (messages.includes(message.toUpperCase())) AuthService.logout();
    });
  }
});
// set auth token if present
const authLink = setContext((_, { headers }) => {
  const token = AuthService.getToken();
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});
// cache options (for managing cache of order items)
const options = {
  typePolicies: {
    Order: {
      fields: {
        amounts: {
          merge(existing = [], incoming) {
            return incoming;
          },
        },
        counts: {
          merge(existing = [], incoming) {
            return incoming;
          },
        },
      },
    },
  },
};
// create apollo client using auth and http link
const client = new ApolloClient({
  link: errorLink.concat(authLink).concat(httpLink),
  cache: new InMemoryCache(options),
  name: "operator",
  version: "1.0",
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <Toaster />
    <AuthProvider>
      <ApolloProvider client={client}>
        <Router>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </Router>
      </ApolloProvider>
    </AuthProvider>
  </HelmetProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
