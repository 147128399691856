import { useState, useContext, useEffect } from "react";
import { AuthContext } from "contexts/Auth";
import { OrderContext } from "contexts/Order";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ORDER } from "utils/queries";
import toast from "react-hot-toast";
import UserLayout from "layouts/User";
import HeadSection from "components/sections/Head";
import OrderStats from "components/stats/Order";
import CollectItemsList from "components/lists/CollectItems";
import DistributeItemsList from "components/lists/DistributeItems";
import OrderExpensesList from "components/lists/OrderExpenses";
import CommentsList from "components/lists/Comments";

export default function Order(props) {
  const { mode, canAddExpense, canAddProduct, canCompleteOrder } = props;

  const { user } = useContext(AuthContext);
  const { refetchOrder } = useContext(OrderContext);

  const { id } = useParams();

  const navigate = useNavigate();

  const [summary, setSummary] = useState({
    number: "",
    module: "",
    status: "",
    date: "",
    amounts: {
      cost: 0,
      expense: 0,
    },
    counts: {
      locations: 0,
      items: 0,
    },
  });

  const { startPolling, stopPolling, refetch } = useQuery(GET_ORDER, {
    variables: {
      id,
      ...(mode !== "FULFILL" && {
        location: user.data.location,
      }),
    },
    onCompleted: (data) => {
      if (data.order?.success) {
        const { order } = data.order;

        setSummary({
          number: order.number,
          module: order.module,
          status: order.status,
          date: order.date,
          total: order.amounts?.total,
          amounts: {
            cost: order.amounts?.cost,
            expense: order.amounts?.expense,
          },
          counts: {
            locations: order.counts?.locations,
            items: order.counts?.items,
          },
        });

        // if (order.status !== "PROCESSING") navigate(`/`);
        refetchOrder();
      } else navigate("/"); // redirect to home
    },
    onError: (error) => {
      toast.error("Failed to fetch order details");
      console.error(error);
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    startPolling(1000);
    return () => stopPolling();
  }, [startPolling, stopPolling]);

  return (
    <UserLayout title={`Order ${summary?.number}`}>
      <HeadSection heading={`${summary?.number}`}>
        {canAddExpense && (
          <a
            href={`/add/expense?order=${id}`}
            className="btn btn-outline btn-primary"
          >
            Add Expense
          </a>
        )}
      </HeadSection>
      <div className="flex flex-col gap-6 pt-6">
        {/* order summary */}
        <section>
          <h2 className="text-lg font-semibold mb-2 mx-2">Overview</h2>
          <OrderStats id={id} summary={summary} />
        </section>
        {/* for COLLECT mode */}
        {mode === "COLLECT" && (
          <>
            <CollectItemsList
              mode={mode}
              order={id}
              module={summary.module}
              summary={summary}
              operator={user.data.id}
              refreshOrder={refetch}
              showAction={canAddProduct}
            />
          </>
        )}
        {/* for DISTRIBUTE mode */}
        {mode === "DISTRIBUTE" && (
          <>
            <DistributeItemsList
              mode={mode}
              order={id}
              module={summary.module}
              summary={summary}
              operator={user.data.id}
              refreshOrder={refetch}
              showAction={canCompleteOrder}
            />
          </>
        )}
        {/* order expenses */}
        {canAddExpense && (
          <>
            <OrderExpensesList
              order={id}
              info={summary}
              showAction={canAddExpense}
            />
          </>
        )}
        {/* order comments */}
        <section>
          <h2 className="text-lg font-semibold mb-2 mx-2">Comments</h2>
          <CommentsList entity={"Order"} reference={id} />
        </section>
      </div>
    </UserLayout>
  );
}
