import { useMutation } from "@apollo/client";
import { UPDATE_ORDER_ITEM } from "utils/mutations";
import { LIST_DELIVERABLE_ITEMS } from "utils/queries";
import toast from "react-hot-toast";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

export default function DistributionForm(props) {
  const { item, locations, selectedLocation, handleLocation, refresh, goBack } =
    props;

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: "onTouched" });

  const [updateDelivery, { loading }] = useMutation(UPDATE_ORDER_ITEM, {
    onCompleted: (data) => {
      if (data?.updated?.success) {
        toast.success("Item updated successfully");
        reset();
        refresh();
        goBack();
      }
      if (!data?.updated?.success) toast.error("Item update failed");
    },
    onError: (error) => {
      toast.error(error.message);
      console.error(error);
    },
    refetchQueries: [{ query: LIST_DELIVERABLE_ITEMS }],
  });

  const onSubmit = (data) => {
    const inputs = {
      id: item,
      deliver: {
        location: data.distribution_location,
        quantity: parseInt(data.distribution_quantity * 100),
      },
    };

    updateDelivery({ variables: { inputs } });
  };

  useEffect(() => {
    if (selectedLocation) setValue("distribution_location", selectedLocation);
  }, [selectedLocation, setValue]);

  return (
    <div className="w-full">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col sm:flex-row sm:items-end gap-2 sm:gap-4"
      >
        {/* selectedLocation */}
        <div className="flex-1">
          <label htmlFor="distribution_selectedLocation" className="label">
            <span className="label-text font-semibold">Location</span>
          </label>
          <select
            className="input input-bordered w-full bg-base-200"
            disabled={loading}
            {...register("distribution_location", {
              required: "Location is required",
              onChange: (e) => handleLocation(e.target.value),
              validate: (value) => {
                if (!value) return "Location is required";
                return true;
              },
            })}
          >
            <option value="">Select location</option>
            {locations.map((value, index) => (
              <option key={index} value={value.id}>
                {value.label}
              </option>
            ))}
          </select>
          {errors?.distribution_location && (
            <div className="mt-1 text-red-600">
              <small>{errors?.distribution_location?.message}</small>
            </div>
          )}
        </div>
        {/* quantity */}
        <div className="flex-1">
          <label htmlFor="distribution_quantity" className="label">
            <span className="label-text font-semibold">Quantity</span>
          </label>
          <input
            type="number"
            className="input input-bordered w-full bg-base-200"
            placeholder="Quantity"
            inputMode="numeric"
            min={0.05}
            step={0.05}
            disabled={loading}
            {...register("distribution_quantity", {
              required: "Item collection qty is required",
            })}
          />
          {errors?.distribution_quantity && (
            <div className="mt-1 text-red-600">
              <small>{errors?.distribution_quantity?.message}</small>
            </div>
          )}
        </div>
        {/* submit */}
        <div className="pt-4">
          <button
            className={clsx(
              "btn btn-primary btn-outline w-full",
              loading && "loading"
            )}
            disabled={!isValid || loading}
          >
            Distribute Item
          </button>
        </div>
      </form>
    </div>
  );
}
