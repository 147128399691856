import { gql } from "@apollo/client";

export const PROFILE = gql`
  query Profile {
    profile {
      email
      username
    }
  }
`;

export const LIST_ORDERS = gql`
  query Orders(
    $module: [String]
    $number: String
    $date: [String]
    $status: [String]
    $location: ID
    $page: Int
    $limit: Int
  ) {
    orders(
      module: $module
      number: $number
      date: $date
      status: $status
      location: $location
      page: $page
      limit: $limit
    ) {
      code
      success
      message
      total
      page
      limit
      list: orders {
        id
        number
        module
        date
        close
        amounts {
          total
        }
        counts {
          items
        }
        status
        created
        updated
      }
    }
  }
`;

export const GET_ORDER = gql`
  query Order($id: ID, $location: ID) {
    order(id: $id, location: $location) {
      code
      success
      message
      order {
        id
        number
        module
        date
        close
        timestamps {
          closed
          processed
          completed
        }
        amounts {
          cost
          expense
          total
        }
        counts {
          locations
          items
        }
        invoices {
          id
          location {
            id
            label
            type
          }
          total
          costs {
            expenses
            items
          }
          link
        }
        status
        created
        updated
        counts {
          locations
          items
          expenses
          comments
        }
      }
    }
  }
`;

export const GET_COMMENTS = gql`
  query Comments($entity: String, $reference: ID) {
    comments(entity: $entity, reference: $reference) {
      code
      success
      message
      list: comments {
        id
        by
        on
        message
      }
    }
  }
`;

export const LIST_COLLECTABLE_ITEMS = gql`
  query CollectableItems(
    $order: ID!
    $supplier: ID
    $name: String
    $hideAmounts: Boolean
  ) {
    items: collectableItems(
      order: $order
      supplier: $supplier
      name: $name
      hideAmounts: $hideAmounts
    ) {
      code
      success
      message
      total
      list: collections {
        id
        thing {
          id
          label
          unit
          thumbnail
          group
          flags {
            collected
            delivered
            highlighted
            notified
            requested
          }
        }
        quantity
        average
        requested
        notified
      }
      options {
        suppliers {
          id
          label
          type
        }
      }
    }
  }
`;

export const LIST_DELIVERABLE_ITEMS = gql`
  query DistributableItems(
    $order: ID!
    $location: ID
    $confirming: Boolean
    $name: String
    $hideAmounts: Boolean
  ) {
    items: deliverableItems(
      order: $order
      location: $location
      confirming: $confirming
      name: $name
      hideAmounts: $hideAmounts
    ) {
      code
      success
      message
      list: deliveries {
        id
        quantity
        thing {
          id
          label
          thumbnail
          unit
          group
          type
          flags {
            requested
            notified
            collected
            delivered
            highlighted
          }
        }
        requested
        notified
        collected
      }
      total
      options {
        locations {
          id
          label
          type
        }
        suppliers {
          id
          label
          type
        }
      }
    }
  }
`;

export const LIST_ADDABLE_PRODUCTS = gql`
  query AddableProducts($order: ID!, $module: String!) {
    products: addableProducts(order: $order, module: $module) {
      code
      success
      message
      total
      list: products {
        id
        thumbnail
        label
        unit
        group
      }
    }
  }
`;

export const LIST_TRANSACTIONS = gql`
  query Transactions(
    $module: String
    $reference: ID
    $date: [String]
    $description: String
    $category: String
    $order: ID
    $limit: Int
    $page: Int
  ) {
    transactions(
      module: $module
      reference: $reference
      date: $date
      description: $description
      category: $category
      order: $order
      limit: $limit
      page: $page
    ) {
      code
      success
      message
      total
      page
      limit
      list: transactions {
        id
        date
        module
        entity {
          id
          type
          label
        }
        type
        category
        description
        amount
        receipt
        created
        updated
      }
      summary {
        credits
        debits
        balance
      }
      options {
        modules
        entities {
          id
          type
          label
        }
      }
    }
  }
`;

export const GET_ITEM_INFO = gql`
  query Item($itemId: ID) {
    item(id: $itemId) {
      code
      success
      message
      item {
        id
        name
        quantities {
          requested
          notified
          collected
          delivered
        }
        amounts {
          average
          total
        }
        flags {
          requested
          notified
          collected
          delivered
        }
        counts {
          comments
        }
        highlighted
        product {
          id
          label
        }
        order {
          id
          label
        }
        requests {
          id
          location {
            id
            label
          }
          quantity
        }
        notifications {
          id
          supplier {
            id
            label
          }
          quantity
        }
        collections {
          id
          supplier {
            id
            label
          }
          quantity
          cost
        }
        deliveries {
          id
          location {
            id
            label
          }
          quantity
        }
      }
      options {
        locations {
          id
          label
        }
        suppliers {
          id
          label
        }
      }
    }
  }
`;

export const EXPENSE_CONSTANTS = gql`
  query ExpenseConstants {
    categories: expenseCategories {
      value
      label
    }
    locations {
      locations {
        id
        name
      }
    }
    operators {
      operators {
        id
        name
      }
    }
  }
`;

export const TRANSACTION_CONSTANTS = gql`
  query TransactionConstants($module: String, $feature: String, $role: String) {
    modules: availableModules(feature: $feature, role: $role) {
      value
      label
    }
    locations {
      locations {
        id
        name
      }
    }
    operators {
      operators {
        id
        name
      }
    }
    categories: transactionCategories(module: $module, role: $role) {
      label
      value
      entity
      type
      description
    }
  }
`;

export const LIST_BALANCES = gql`
  query Balances {
    locations {
      locations {
        id
        name
        balance {
          produce
        }
      }
    }
    operators {
      operators {
        id
        name
        balance {
          produce
        }
      }
    }
  }
`;
