import { useState, useEffect, useContext } from "react";
import { AuthContext } from "contexts/Auth";
import { useMutation } from "@apollo/client";
import { ADD_COMMENT, UPDATE_COMMENT } from "utils/mutations";
import { GET_COMMENTS } from "utils/queries";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import clsx from "clsx";

export default function CommentForm(props) {
  const { entity, reference, edit, setEdit, alsoRefetch } = props;

  const { user } = useContext(AuthContext);

  const [mode, setMode] = useState("create");
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  const [addComment, { loading: adding }] = useMutation(ADD_COMMENT, {
    onCompleted: (data) => {
      if (data.created.success) {
        toast.success(data.created.message);
        reset();
        if (alsoRefetch !== undefined) alsoRefetch();
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
    refetchQueries: [{ query: GET_COMMENTS, variables: { entity, reference } }],
  });

  const [updateComment, { loading: updating }] = useMutation(UPDATE_COMMENT, {
    onCompleted: (data) => {
      if (data?.updated?.success) {
        reset();
        setMode("create");
        setEdit(null);
        toast.success(data.updated.message);
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
    refetchQueries: [{ query: GET_COMMENTS, variables: { entity, reference } }],
  });

  const onSubmit = (data) => {
    const payload = {
      user: user?.data?.id,
      entity,
      reference,
      message: data.comment_message,
    };

    const inputs = !!edit ? { id: edit.id, ...payload } : payload;

    if (mode === "edit") updateComment({ variables: { inputs } });
    else addComment({ variables: { inputs } });

    reset();
  };

  useEffect(() => {
    if (!!edit) {
      setMode("edit");
      setValue("comment_message", edit?.message);
    } else {
      setMode("create");
      reset();
    }
  }, [edit, setValue, reset]);

  useEffect(() => {
    if (adding || updating) setLoading(true);
    else setLoading(false);
  }, [loading, adding, updating]);

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* message */}
        <div>
          <label htmlFor="message" className="label">
            <span className="label-text font-semibold">Message</span>
          </label>
          <textarea
            name="message"
            rows={5}
            placeholder="Write your comment here..."
            className="input input-bordered w-full h-24 bg-base-200"
            disabled={loading}
            {...register("comment_message", {
              required: "Message is required",
            })}
          />
          {errors?.comment_message && (
            <div className="text-red-600">
              <small>{errors?.comment_message?.message}</small>
            </div>
          )}
        </div>
        {/* submit */}
        <div className="flex pt-4">
          <button
            type="submit"
            className={clsx(
              "btn btn-primary btn-outline",
              loading && "loading"
            )}
          >
            {mode === "edit" ? "Update comment" : "Add comment"}
          </button>
          {mode === "edit" && (
            <span
              className="btn btn-outline ml-2"
              onClick={() => setEdit(null)}
            >
              Cancel
            </span>
          )}
        </div>
      </form>
    </div>
  );
}
