import { XMarkIcon } from "@heroicons/react/24/outline";

export default function Drawer(props) {
  const { title = "Drawer", children } = props;

  return (
    <div className="fixed inset-y-0 right-0 flex flex-col h-screen w-96 z-50 shadow-md">
      <div className="flex items-center justify-between py-6 px-6 z-50 bg-base-200 border-b">
        <div className="flex items-center space-x-4">
          <div className="text-2xl font-semibold">{title}</div>
        </div>
        <div className="flex items-center space-x-4">
          <button
            className="p-2 rounded-full bg-base-200 text-secondary hover:bg-secondary hover:text-white transition duration-200"
            onClick={() => props.setOpen(false)}
          >
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>
      </div>
      <div className="flex-1 bg-base-200 overflow-y-auto p-4 z-50">
        {children}
      </div>
    </div>
  );
}
