import { useState, useEffect, useContext } from "react";
import { AuthContext } from "contexts/Auth";
import { OrderContext } from "contexts/Order";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ITEM_INFO } from "utils/queries";
import clsx from "clsx";
import UserLayout from "layouts/User";
import LoadingLayout from "layouts/Loading";
import HeadSection from "components/sections/Head";
import ItemStats from "components/stats/Item";
import { Tab } from "@headlessui/react";
import CollectionForm from "components/forms/Collection";
import CollectionList from "components/lists/Collection";
import DistributionForm from "components/forms/Distribution";
import DistributionList from "components/lists/Distribution";
import CommentsList from "components/lists/Comments";
import { calcTotal } from "utils/helpers";

export default function Item(props) {
  const { canCollect, canDistribute } = props;

  const allowedTabs = [];

  if (canCollect) allowedTabs.push({ label: "Collect", value: "collect" });
  if (canDistribute)
    allowedTabs.push({ label: "Distribute", value: "distribute" });

  const { user } = useContext(AuthContext);
  const { order } = useContext(OrderContext);

  const [info, setInfo] = useState(null);

  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [tabs] = useState(allowedTabs);
  const [supplier] = useState(searchParams.get("supplier"));
  const [location] = useState(searchParams.get("location"));
  const [locations, setLocations] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [collections, setCollections] = useState([]);
  const [distributions, setDistributions] = useState([]);

  const { loading, refetch, startPolling, stopPolling } = useQuery(
    GET_ITEM_INFO,
    {
      onCompleted: (data) => {
        if (data.item?.success) {
          const { item } = data.item;
          setInfo({
            id: item.id,
            name: item.name,
            requested: item.quantities.requested,
            notified: item.quantities.notified,
            collected: item.quantities.collected,
            distributed: item.quantities.delivered,
            average: item.amounts.average,
            total: item.amounts.total,
          });
          // form options
          setLocations(data.item.options.locations);
          setSuppliers(data.item.options.suppliers);

          // combine list of collections and notifications (based on supplier.id)
          const collectionSuppliers =
            item.collections.map((c) => c.supplier.id) || [];
          const notificationSuppliers =
            item.notifications.map((n) => n.supplier.id) || [];
          const listBySupplier = [
            ...new Set([...collectionSuppliers, ...notificationSuppliers]),
          ];
          const collectionsList = listBySupplier.map((supplier) => {
            const name =
              data.item.options.suppliers.find((s) => s.id === supplier)
                ?.label ?? "-";
            const notified =
              item.notifications.find((n) => n.supplier.id === supplier)
                ?.quantity ?? 0;
            const collected =
              item.collections.find((c) => c.supplier.id === supplier)
                ?.quantity ?? 0;
            const cost =
              item.collections.find((c) => c.supplier.id === supplier)?.cost ??
              0;

            return {
              id: supplier,
              name,
              notified,
              collected,
              cost,
              item: item.id,
            };
          });
          setCollections(collectionsList);
          // combine list of distributions and requests (based on location.id)
          const requestLocations =
            item.requests.map((r) => r.location.id) || [];
          const deliveriesLocations =
            item.deliveries.map((d) => d.location.id) || [];
          const listByLocation = [
            ...new Set([...requestLocations, ...deliveriesLocations]),
          ];
          const distributionsList = listByLocation.map((location) => {
            const name =
              data.item.options.locations.find((l) => l.id === location)
                ?.label ?? "-";
            const requested =
              item.requests.find((r) => r.location.id === location)?.quantity ??
              0;
            const delivered =
              item.deliveries.find((d) => d.location.id === location)
                ?.quantity ?? 0;
            const cost = calcTotal(item.amounts.average, delivered);

            return {
              id: location,
              name,
              requested,
              delivered,
              cost,
              item: item.id,
            };
          });
          setDistributions(distributionsList);
        }
      },
      onError: (error) => {
        console.error(error);
      },
      variables: { itemId: id },
    }
  );

  const handleBack = () => {
    let url;
    const mode = searchParams.get("mode");

    if (mode === "collect") {
      url = `/order/${order.id}/collect`;
      if (supplier) url += `?supplier=${supplier}`;
    }
    if (mode === "distribute") {
      url = `/order/${order.id}/distribute`;
      if (location) url += `?location=${location}`;
    }

    navigate(url);
  };

  const handleTabs = (index) => {
    const tab = tabs[index];
    searchParams.set("mode", tab.value);
    if (tab.value === "collect") searchParams.delete("location");
    if (tab.value === "distribute") searchParams.delete("supplier");
    setSearchParams(searchParams);
  };

  const handleLocation = (location) => {
    if (!!location) searchParams.set("location", location);
    else searchParams.delete("location");
    setSearchParams(searchParams);
  };

  const handleSupplier = (supplier) => {
    if (!!supplier) searchParams.set("supplier", supplier);
    else searchParams.delete("supplier");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const paramsTab = searchParams.get("mode");
    const selectedTab = tabs[selectedIndex].value;
    if (selectedTab !== paramsTab) {
      const index = tabs.findIndex((tab) => tab.value === paramsTab);
      setSelectedIndex(index);
    }
  }, [selectedIndex, searchParams, tabs, setSearchParams]);

  useEffect(() => {
    if (info) startPolling(1000);
    return () => stopPolling();
  }, [info, startPolling, stopPolling]);

  if (loading)
    return (
      <UserLayout>
        <LoadingLayout />
      </UserLayout>
    );

  return (
    <>
      <UserLayout title="Item">
        <HeadSection heading={info?.name}>
          <button
            className="btn btn-secondary btn-outline"
            onClick={handleBack}
          >
            Back
          </button>
        </HeadSection>
        <div className="flex flex-col gap-6 pt-6">
          {info && (
            <>
              <section>
                <h2 className="text-lg font-semibold mb-2 mx-2">Summary</h2>
                <ItemStats
                  requested={info?.requested}
                  notified={info?.notified}
                  collected={info?.collected}
                  distributed={info?.distributed}
                  average={info?.average}
                  total={info?.total}
                />
              </section>
              <section>
                <Tab.Group selectedIndex={selectedIndex} onChange={handleTabs}>
                  <Tab.List className="flex space-x-1 rounded bg-white p-1 mb-6">
                    {tabs.map((tab, index) => (
                      <Tab
                        key={index}
                        className={({ selected }) =>
                          clsx(
                            "w-full py-3 text-md tracking-wider font-medium",
                            "rounded",
                            selected ? "bg-primary text-white" : "text-primary"
                          )
                        }
                      >
                        {tab.label}
                      </Tab>
                    ))}
                  </Tab.List>
                  <Tab.Panels className="flex flex-col gap-6">
                    {/* item collections */}
                    <Tab.Panel>
                      <section className="flex flex-col gap-6">
                        {order?.status === "PROCESSING" && (
                          <CollectionForm
                            item={id}
                            operator={user?.data.operator}
                            suppliers={suppliers}
                            selectedSupplier={supplier}
                            handleSupplier={handleSupplier}
                            refresh={refetch}
                            goBack={handleBack}
                          />
                        )}
                        <CollectionList
                          item={id}
                          list={collections}
                          refresh={refetch}
                        />
                      </section>
                    </Tab.Panel>
                    {/* item distributions */}
                    <Tab.Panel>
                      <section className="flex flex-col gap-6">
                        {order?.status === "PROCESSING" && (
                          <DistributionForm
                            item={id}
                            operator={user?.data.operator}
                            locations={locations}
                            selectedLocation={location}
                            handleLocation={handleLocation}
                            refresh={refetch}
                            goBack={handleBack}
                          />
                        )}
                        <DistributionList
                          item={id}
                          list={distributions}
                          refresh={refetch}
                        />
                      </section>
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </section>
            </>
          )}
          {/* order comments */}
          <section>
            <h2 className="text-lg font-semibold mb-2 mx-2">Comments</h2>
            <CommentsList entity={"Item"} reference={id} />
          </section>
        </div>
      </UserLayout>
    </>
  );
}
