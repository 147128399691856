import { useForm } from "react-hook-form";
import { useState, useEffect, useContext } from "react";
import { LOGIN } from "utils/mutations";
import { useMutation } from "@apollo/client";
import { AuthContext } from "contexts/Auth";
import toast from "react-hot-toast";

export default function LoginForm() {
  const { login } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);

  const { register, handleSubmit } = useForm();

  const [doLogin, { loading }] = useMutation(LOGIN, {
    onCompleted: (data) => {
      setLoader(false);
      if (data?.login?.success) login(data.login);
      if (!data?.login?.success) toast.error("Invalid type or credentials!");
    },
    onError: (err) => {
      setLoader(false);
      toast.error(err.message);
    },
  });

  const onSubmit = (data) => {
    const { username, password } = data.login;
    doLogin({
      variables: {
        username: username.toLowerCase(),
        password,
      },
    });
  };

  useEffect(() => {
    if (loading) setLoader(true);
  }, [loading]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
      <div>
        <label className="label">
          <span className="label-text font-semibold">Username</span>
        </label>
        <input
          type="text"
          placeholder="john.doe"
          className="input input-bordered w-full"
          {...register("login.username")}
          required
        />
      </div>
      <div>
        <label className="label">
          <span className="label-text font-semibold">Password</span>
        </label>
        <input
          type="password"
          placeholder="********"
          className="input input-bordered w-full"
          {...register("login.password")}
          required
        />
      </div>
      <div className="pt-4">
        <button
          type="submit"
          className={
            loader
              ? "btn btn-secondary w-full loading"
              : "btn btn-secondary w-full"
          }
        >
          Login
        </button>
      </div>
    </form>
  );
}
