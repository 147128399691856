import moment from "moment";
import AWS from "aws-sdk";

const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
const REGION = process.env.REACT_APP_REGION;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
});

export const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export const uploadFile = (file, type) => {
  const params = {
    ACL: "public-read",
    Body: file,
    Key: `${type}/${file.name}`,
  };

  return myBucket.upload(params).promise();
};

export const formatDate = (date) => {
  const [day, month, year] = date.split("-");
  return `${year}-${month}-${day}`;
};

export const formatISODate = (date, syntax) => {
  return moment(date / 1000, "X").format(syntax);
};

export const toAmPm = (time) => {
  const [hours, minutes] = time.split(":");
  const ampm = hours >= 12 ? "pm" : "am";

  return `${hours % 12 || 12}:${minutes} ${ampm} `;
};

export const currency = (cents) => {
  const formatter = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
  });

  return formatter.format(cents / 100);
};

export const compactCurrency = (cents) => {
  const formatter = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
    notation: "compact",
  });

  return formatter.format(cents / 100);
};

export const unit = (qty = 0, unit) => {
  const formatter = new Intl.NumberFormat("en-AU", {
    style: "decimal",
  });

  return `${formatter.format(qty / 100)} ${unit ?? ""}`;
};

export const compactUnit = (qty, unit) => {
  const formatter = new Intl.NumberFormat("en-AU", {
    style: "decimal",
    notation: "compact",
  });

  return `${formatter.format(qty / 100)} ${unit ?? ""}`;
};

export const upperFirst = (str) => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : "";
};

export const statusTagColor = (str) => {
  if (str === "ACTIVE") return `text-white bg-info`;
  if (str === "INACTIVE") return `text-white bg-base-300`;
  if (str === "SKIPPED") return `text-white bg-warning`;
  if (str === "DRAFT") return `text-white bg-warning`;
  if (str === "PROCESSING") return `text-white bg-info`;
  if (str === "COMPLETE") return `text-white bg-success`;
  if (str === "COMPLETED") return `text-white bg-success`;
  if (str === "CANCELLED") return `text-white bg-base-300`;
  if (str === "INSEASON") return `text-white bg-success`;

  return `text-black bg-base-200`;
};

export const thumbnailUri = (thumbnail) => {
  const url = process.env.REACT_APP_S3_BUCKET_URL;

  if (thumbnail) return `${url}/thumbnail/${thumbnail}`;
  return `${url}/thumbnail/placeholder-square.jpg`;
};

export const receiptUri = (receipt) => {
  const url = process.env.REACT_APP_S3_BUCKET_URL;

  return `${url}/${receipt}`;
};

export const currentFinancialYear = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();

  if (month < 6) {
    return `FY ${year - 1}-${year}`;
  }

  return `FY ${year}-${year + 1}`;
};

export const calcTotal = (average, qty) => {
  return parseInt(Number((qty / 100) * average).toFixed(0));
};

export const nameToSlug = (name) => {
  return name.toLowerCase().replace(/ /g, "_");
};
