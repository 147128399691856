import { Helmet } from "react-helmet-async";
import logo from "assets/logo.svg";

export default function PublicLayout(props) {
  return (
    <>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      <div className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-base-100 py-2 md:py-12">
        <header>
          <div className="pb-8">
            <img src={logo} alt="App logo" className="w-48 mx-auto" />
          </div>
        </header>
        <main>
          <div className="relative bg-white px-6 pt-10 pb-12 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:px-10">
            {props.children}
          </div>
        </main>
        <footer>
          <div className="text-center p-4 text-base-content">
            <a
              href="https://logicsync.com.au/"
              className="text-sm font-semibold uppercase tracking-wide cursor-pointer"
              target="_blank"
              rel="noreferrer"
            >
              Powered by LogicSync
            </a>
          </div>
        </footer>
      </div>
    </>
  );
}
