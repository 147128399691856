import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { useQuery } from "@apollo/client";
import { LIST_COLLECTABLE_ITEMS } from "utils/queries";
import { unit, formatISODate } from "utils/helpers";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import Drawer from "layouts/Drawer";
import AddProductsList from "./AddProducts";
import { PrinterIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import { collectItemsList } from "utils/pdf";
import LoadingLayout from "layouts/Loading";

export default function CollectItemsList(props) {
  const { order, summary, module, showAction } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [name, setName] = useState("");

  const selectedSupplier = searchParams.get("supplier");
  const [debouncedName] = useDebounce(name, 500);

  const { loading: fetching, refetch } = useQuery(LIST_COLLECTABLE_ITEMS, {
    variables: {
      order,
      supplier: !!selectedSupplier ? selectedSupplier : null,
      name: !!debouncedName ? debouncedName : null,
      hideAmounts: true,
    },
    onCompleted: (data) => {
      if (data.items?.success) {
        setSuppliers(data.items?.options?.suppliers);

        // keep only items that have value.notified > 0 (if supplier is selected)
        const filtered = !!selectedSupplier
          ? data.items?.list.filter(
              (item) => item.notified > 0 || item.quantity > 0
            )
          : data.items?.list;
        setFilteredItems(filtered);
      }
    },
    onError: (error) => {
      console.error(error.message);
    },
    fetchPolicy: "cache-and-network",
  });

  const handleSupplier = (e) => {
    if (e.target.value === "") searchParams.delete("supplier");
    else searchParams.set("supplier", e.target.value);
    setSearchParams(searchParams);
  };

  const handlePrint = () => {
    const supplierName = !!selectedSupplier
      ? suppliers.find((s) => s.id === selectedSupplier).label
      : "All Suppliers";

    const payload = {
      number: summary?.number ?? "-",
      date: formatISODate(summary.date, "DD/MM/YYYY"),
      name: supplierName,
      items: filteredItems,
    };

    collectItemsList(payload);
  };

  const getLink = (item, supplier) => {
    if (supplier)
      return `/item/${item.thing.id}?mode=collect&supplier=${supplier}`;
    return `/item/${item.thing.id}?mode=collect`;
  };

  return (
    <>
      <section>
        <div className="flex flex-wrap flex-col md:flex-row md:justify-between md:items-baseline mb-4">
          {/* title */}
          <h2 className="text-lg font-semibold mb-2 mx-2">Collect Items</h2>
          <div className="flex flex-row flex-wrap md:flex-nowrap gap-2 items-center mx-2">
            {/* print */}
            <button
              className="btn btn-sm btn-outline btn-primary"
              onClick={handlePrint}
            >
              <PrinterIcon className="h-4 w-4 mr-2" /> Print
            </button>
            {showAction && (
              <button
                className={clsx("btn btn-sm btn-outline btn-primary")}
                onClick={() => setOpenDrawer(true)}
              >
                Add Product
              </button>
            )}
            <div className="flex gap-2 items-center">
              {/* select supplier */}
              <label
                htmlFor="supplier"
                className="text-sm font-semibold sr-only"
              >
                Supplier
              </label>
              <select
                name="supplier"
                id="supplier"
                className="rounded border-2 border-error w-full min-w-[240px]"
                disabled={fetching}
                value={selectedSupplier ?? ""}
                onChange={handleSupplier}
              >
                <option value="">All suppliers</option>
                {suppliers.map((value, index) => (
                  <option key={index} value={value.id}>
                    {value.label}
                  </option>
                ))}
              </select>
              <button
                className="btn btn-sm btn-ghost"
                onClick={() => refetch()}
              >
                <ArrowPathIcon className="w-4 h-4" />
              </button>
            </div>
          </div>
        </div>
        {/* filter by name */}
        <div className="my-2">
          <input
            type="search"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Search items"
            className="input w-full bg-white"
          />
        </div>
        {fetching ? (
          <LoadingLayout />
        ) : (
          <>
            {filteredItems && filteredItems.length > 0 ? (
              <>
                <ul className="flex flex-col gap-1">
                  {filteredItems.map((value, index) => (
                    <li className="bg-white rounded px-2 py-1" key={index}>
                      <Link
                        to={getLink(value, selectedSupplier)}
                        className="flex items-center gap-1"
                      >
                        <div className="font-bold font-mono text-xs">
                          #{index + 1}
                        </div>
                        <div className="flex flex-col flex-1 px-1 py-2">
                          <div className="flex flex-col gap-3 md:flex-row md:items-center md:justify-between">
                            {/* product info */}
                            <div className="flex flex-row gap-2 items-center">
                              <div className="relative z-10 flex-shrink-0 w-12">
                                <img
                                  src={value.thing.thumbnail}
                                  className="min-w-12 min-h-12 w-12 h-12 object-cover rounded"
                                  alt={value.thing.label ?? "-"}
                                />
                                {value.thing?.flags?.highlighted && (
                                  <span className="absolute -top-1 -left-1 flex h-2 w-2">
                                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-success opacity-75"></span>
                                    <span className="relative inline-flex rounded-full h-2 w-2 bg-success"></span>
                                  </span>
                                )}
                              </div>
                              <div className="flex-1 flex-shrink-0 px-2">
                                <h3 className="text-lg font-semibold tracking-wider">
                                  {value.thing.label ?? "-"}{" "}
                                </h3>
                                <span className="text-xs font-semibold">
                                  {value.thing.group ?? "-"}
                                </span>{" "}
                                -{" "}
                                <span className="text-xs">
                                  {value.thing.unit ?? "-"}
                                </span>
                              </div>
                            </div>
                            {/* parameters */}
                            <div className="flex flex-row justify-center items-center md:justify-end gap-2">
                              <div className="flex flex-row items-center justify-center text-center divide-x">
                                {!selectedSupplier && (
                                  <div className="px-2">
                                    <p className="text-sm">
                                      Requested:{" "}
                                      <span className="font-mono font-bold text-md">
                                        {unit(value.requested)}
                                      </span>
                                    </p>
                                  </div>
                                )}
                                <div className="px-2">
                                  <p className="text-sm">
                                    Notified:{" "}
                                    <span className="font-mono font-bold text-md">
                                      {unit(value.notified)}
                                    </span>
                                  </p>
                                </div>
                                <div className="px-2">
                                  <p className="text-sm">
                                    Collected:{" "}
                                    <span className="font-mono font-bold text-md">
                                      {unit(value.quantity)}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bg-gray-100 p-1.5 rounded-full">
                          <ChevronRightIcon className="h-4 w-4 text-gray-700" />
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
                <p className="text-center pt-2 text-xs tracking-wider font-bold">
                  Total: {filteredItems.length} items
                </p>
              </>
            ) : (
              <p className="text-center text-gray-500">No items found</p>
            )}
          </>
        )}
      </section>
      {openDrawer && (
        <Drawer setOpen={setOpenDrawer} title="Add Product to Order">
          <AddProductsList
            order={order}
            module={module}
            refresh={refetch}
            close={() => setOpenDrawer(false)}
          />
        </Drawer>
      )}
    </>
  );
}
